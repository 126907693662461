@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
body{
  padding: 0;
  margin: 0;
  width: 100% !important;

}

.absolute.inset-0.w-full.h-full.pointer-events-auto.z-\[9995\].bg-black.bg-opacity-60.backdrop-blur-sm {
  backdrop-filter: none;
  --tw-bg-opacity: 0 !important;
  background-color: transparent !important;
}


@media (max-width: 991px) {
  .grid.place-items-center.fixed.w-screen.h-screen.bg-black.bg-opacity-60.backdrop-blur-sm{
    height: 100% !important;
    width: 100% !important;
    
  }  

  .grid.place-items-center.fixed.w-screen.h-screen.bg-black.bg-opacity-60.backdrop-blur-sm form{
    font-size: 24px !important;
    color: black;
  }

  .grid.place-items-center.fixed.w-screen.h-screen.bg-black.bg-opacity-60.backdrop-blur-sm form input{
    font-size: 16px !important;
  }

  .grid.place-items-center.fixed.w-screen.h-screen.bg-black.bg-opacity-60.backdrop-blur-sm form select{
    font-size: 16px !important;
  }

  .grid.place-items-center.fixed.w-screen.h-screen.bg-black.bg-opacity-60.backdrop-blur-sm form label{
    font-size: 16px !important;
  }
  
}

.wrapper-1{
  width:100%;
  height:50vh;
  display: flex;
flex-direction: column;
}
.wrapper-2{
  padding :30px;
  text-align:center;
}
h1{
    font-family: 'Kaushan Script', cursive;
  font-size:4em;
  letter-spacing:3px;
  color:#5892FF ;
  margin:0;
  margin-bottom:20px;
}
.wrapper-2 p{
  margin:0;
  font-size:1.3em;
  color:#aaa;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing:1px;
}
.go-home{
  color:#fff;
  background:#5892FF;
  border:none;
  padding:10px 50px;
  margin:30px 0;
  border-radius:30px;
  text-transform:capitalize;
  box-shadow: 0 10px 16px 1px rgba(174, 199, 251, 1);
}


@media (min-width:360px){
  h1{
    font-size:4.5em;
  }
  .go-home{
    margin-bottom:20px;
  }
}

@media (min-width:600px){
  .content{
  max-width:1000px;
  margin:0 auto;
}
  .wrapper-1{
  height: initial;
  max-width:620px;
  margin:0 auto;
  margin-top:50px;
  box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2);
}

}

@media only screen and (max-width: 600px) and (min-width: 0px) {
  .captchaContainer{
    flex-direction: column;
    gap: 20px;
  }

  .captchaBox {
    margin-right: 0;
  }

  .captchaInput {
    width: 100%;
  }

  .contactForm{
    width: 100% !important;
    margin: 0 1em;
  }

  .logoContainer{
    margin-left: 1rem;
  }

  .logo{
    width: 4.5rem;
    height: 2rem;
  }

  .logoText{
    font-size: 1rem;
    margin: 0;
  }

  .drawerBtn{
    width: 3rem;
    height: 2rem;
    padding: 0.5rem;
  }

  .drawerIcon{
    width: 3rem;
    height: 2rem;
  }

  .footerLink{
    font-size: 16px;
  }

  .footerContainer{
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
  }
}
